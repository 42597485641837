/*-----------------
    16. Gallery
-----------------------*/

.gallery-section {
    @include margin-padding(null, 80px 0 56px 0);
    @include respond-below(custom991) {
        @include margin-padding(null, 50px 0 26px 0);
    }
    .gallery-widget {
        position: $__relative;
        margin: 0 0 25px 0;
        -webkit-transition: all 0.7s;
        -moz-transition: all 0.7s;
        -o-transition: all 0.7s;
        transition: all 0.7s;
        img {
            // border-radius: 4px;
        }
    }
}    
.fancybox-image {
    // border-radius: 10px;
    border: 1px solid $__white;
}