/*-----------------
    19. Invoice
-----------------------*/

.invoice-section {
    position: $__relative;
    @include margin-padding(null, 80px 0);
    @include respond-below(custom991) {
        @include margin-padding(null, 60px 0);
    }
    @include respond-below(custom767) {
        @include margin-padding(null, 50px 0);
    }
    .card-body {
        background: $__white;
        box-shadow: 0px 4px 24px rgba(225, 225, 225, 0.25);
        // border-radius: 14px;
        padding: 40px;
        max-width: 1076px;
        margin: $__auto;
            @include respond-below(custom575) {
                @include margin-padding(null, 20px);
            }
    }
    .invoice-item {     
        @include margin-padding(0 0 22px, null); 
        .invoice-info {              
            text-align: $__right; 
                @include respond-below(custom575) {
                    @include margin-padding(10px 0 0, null);
                    text-align: $__left; 
                }           
            h1 {
                font-weight: 700;
                font-size: $__font__size__32;
                color: $__gray;
                @include margin-padding(0 0 10px, null);
                @include respond-below(custom1199) {
                    font-size: $__font__size__22 !important;
                }
            }
            h6 {
                font-weight: $__medium;
        font-size: $__font__size__14;
                color: $__sandstone;
            }
        }
    }
    .invoice-item-bill {
        position: $__relative;
        background: $__goose-gray;
        // border-radius: 10px;
        padding: 30px;
        @include margin-padding(0 0 37px, null);
            @include respond-below(custom768) {
                @include margin-padding(null, 15px);
            } 
        &:before {
            content: '';
            background: url(../../../../public/assets/img/bg/invoice.png) no-repeat;
            position: $__absolute;
            top: -45px;
            left: -45px;
            width: 179px;
            height: 180px;
            background-size: cover;
        }
        ul {
            position: $__relative;
            display: $__flex;
            flex-flow: row wrap;
            padding-left: 0;
            list-style: $__none;
            margin: 0;
            li {
                -ms-flex: 33.33%;
                flex: 33.33%;
                max-width: 33.33%;
                border-left: 1px solid $__cylindrical;
                display: $__flex;
                justify-content: $__center;                 
                    @include respond-below(custom575) {
                        -ms-flex: 100%;
                        flex: 100%;
                        max-width: 100%;
                        border-left: 0;
                        padding-left: 0;
                        justify-content: start;    
                        @include margin-padding(0 0 15px, null);
                            &:last-child {
                                @include margin-padding(0, null);
                            }
                    }
                &:first-child {                    
                    border-left: 0;
                    @extend %jusflex-start
                }
                .invoice-info {
                    h6 {
                        font-weight: 500;
                        font-size: 18px;
                        color: $__white;
                        @include margin-padding(0 0 15px, null);
                    }
                    p {
                        font-size: 14px;
                        color: $__white;
                        @include margin-padding(0 0 15px, null);
                    }
                }  
            }
        }
    }
    .payment-details {
        margin-top: 40px;
        max-width: 950px;
        .invoice-terms {
                @include respond-below(custom575) {
                    @include margin-padding(0 0 20px, null);
                }
            h6 {
                font-weight: $__medium;
                font-size: $__font__size__18;
                color: $__gray;
                margin-bottom: 18px;
            }            
            .invocie-note {
                border: 2px dashed $__grey;
                // border-radius: 4px;
                max-width: 266px;
                padding: 20px;
                h6 {
                    font-weight: 500;
                    font-size: 18px;
                    color: $__gray__dark;
                    @include margin-padding(0 0 20px, null);
                }
                p {
                    font-weight: 500;
                    font-size: 14px;
                    color: $__gray;
                }
            }
        }
    }
    .invoice-total {
        background: $__light__grayish;
        border: 2px dashed $__alice__blue;
        @include margin-padding(33px 0 33px, 10px);
        text-align: $__right;
        h4 {
            font-weight: $__medium;
            font-size: $__font__size__20;
            color: $__goose-gray;
            @include respond-below(custom767) {
                font-size: $__font__size__18;
            }
            span {
                padding-left: 88px;
                    @include respond-below(custom575) {
                        @include margin-padding(null, 0);
                    }
            }
        }
    }
    .invoice-total-box {
        .invoice-total-inner {
            p {
                font-weight: $__medium;
                font-size: $__font__size__14;
                color: $__gray__lightdark;
                margin-bottom: 25px;
                span {
                    float: $__right;
                    font-weight: 700;
                    font-size: 15px;
                    color: $__gray;
                }
            }
        }
    }
    .invoice-note-footer {   
        .invocie-note {
            @include margin-padding(0 0 20px, null);
            h6 {
                font-weight: $__medium;
                font-size: $__font__size__18;
                color: $__gray__dark;
                @include margin-padding(0 0 13px, null);
            }
            p {
                font-weight: $__medium;
                font-size: $__font__size__14;
                color: $__gray;
            }
        }     
        .invoice-sign {
            float: $__right;
                @include respond-below(custom575) {
                    float: $__left;
                }
            img {
                @include margin-padding(0 0 19px, null);
                @include respond-below(custom991) {
                    @include margin-padding(0, null);
                }
            }
            span {
                font-weight: $__medium;
                font-size: $__font__size__18;
                color: $__goose-gray;
                text-align: $__center;
            }
        }
    }
}